import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://2f92e008b58c5b8c0e421f6858deabd0@o1025753.ingest.us.sentry.io/4507850771791872",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});